
import { defineComponent, getCurrentInstance, reactive, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "Index",

  setup() {
    const { proxy }: any = getCurrentInstance();
    const store = useStore();
    // 轮播图
    const banner = ref([]);
    // 热门商品
    const hotGoods = ref([]);
    const ball = reactive({
      show: false, //是否显示小球
      el: "", //获取小球的dom
    });

    // 初始化
    async function init() {
      const ret = await proxy.$http.get("/api/home");
      console.log(ret);
      if (ret.data.code === 0) {
        banner.value = ret.data.data.banner;
        hotGoods.value = ret.data.data.hotGoods;
      }
    }

    // 加入购物车
    function toCart(event: any, item: any) {
      // console.log(e);
      store.commit("toCart", item);
      // 显示小球
      ball.show = true;
      // 获取点击元素
      ball.el = event.target;
    }

    //小球动画
    function beforeEnter(el: any) {
      let dom: any = ball.el;
      // 获取点击dom位置
      let rect = dom.getBoundingClientRect();
      let x = rect.left - innerWidth * 0.50;
      // let x = rect.left - innerWidth * 0.62;
      let y = -(innerHeight - rect.top);
      el.style.display = "block";
      el.style.transform = `translate3d(0,${y}px,0)`;
      let inner = el.querySelector(".inner");
      inner.style.transform = `translate3d(${x}px,0,0)`;
    }
    function enter(el: any, done: any) {
      // 触发重绘
      document.body.offsetHeight;
      // 小球回家
      el.style.transform = `translate3d(0,0,0)`;
      let inner = el.querySelector(".inner");
      inner.style.transform = `translate3d(0,0,0)`;
      // 过渡完成后执行的事件
      el.addEventListener("transitionend", done);
    }
    function afterEnter(el: any) {
      // 隐藏小球
      ball.show = false;
      el.style.display = "none";
    }

    init();

    return {
      banner,
      hotGoods,
      ball,
      init,
      toCart,
      beforeEnter,
      enter,
      afterEnter,
    };
  },
});
